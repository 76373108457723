import React from "react";
import Store from "./Common/Store.js";
import ComDecom from "./Common/ComDecom.js";
import APICall from "./Common/APICall.js";
import APIContact from "./Common/APIContact";
import Utils from "./Common/Utils.js";
import EventBus from './Common/EventBus.js';
import Index from "./Components/Index";
// import ErrorBoundary from "./ErrorBoundary.js";
// import RegisterIndex from './Components/Register/GenericRegister';
// import RegisterIndex from './Components/Register/GenericRegisterHome';
// import FckRegister from './Components/Register/FckRegister';
import RegisterHome from "./Components/Register/RegisterHome";
// import RegisterIndex from './Components/Register/RegisterIndex';
import ConfigIndex from "./Components/ConfigIndex";
import ModuleConfigHOC from "./Components/Moduleconfig/ModuleConfigHOC";
// import ReportingHOC from './Components/Reporting/ReportingHOC';
import ReportingHOC from "./Components/Reporting/ReportingIndex";
import CSLPageloader from "./Components/Common/CSLPageloader";
import CSLLoader from "./Components/Common/CSLLoader";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AlertBox from './Components/Common/AlertBox';
import Reload from './Common/Reload.js';
import authService from 'mcc-front-aux/dist/authService';
import siteLayout from 'mcc-front-aux/dist/siteLayout'

/*global localStorage*/

const moment = require("moment");
const last_action_time = moment().unix();

class AppContent extends React.Component {
  state = { ready: false, task_loading: true, alert_param: null };

  constructor(props) {
    super(props);
    // // // console.log("Current url:", window.location.href);
    // const current_url = window.location.href;
    Utils.setEnv("filechecker");
    Store.updateStore("module_name", "filechecker");
    this.callContact();
  }

  callContact() {
    let postData = { command: "contactlist" };
    let api = new APIContact();
    console.log("calling callContact");
    let j = api.command(postData, this.processContactData);
    //console.log("return true", j)
  }

  processContactData = (result) => {
    console.log("after callContact");
    let cl = ComDecom.deCompress(result.result["contactlist"], true, "ID");
    result.result["contactlist"] = cl;
    localStorage.setItem("contactlist", JSON.stringify(result));
  };

  componentDidMount() {
    let windowUrl = window.location.href;
    let pieces = windowUrl.split("/");
    let is_task_id = pieces[pieces.length - 1];
    console.log("is_task_id", is_task_id);
    EventBus.registerEvent('system_err', 'AppContent', this.system_err)

    if (windowUrl.indexOf("register") != -1 || (is_task_id !== "" && !isNaN(is_task_id))) {
      this.setState({ task_loading: false });
    }

    let postData = { command: "index" };
    let api = new APICall();
    api.command(postData, this.processIndexData);
  }

  system_err = (error) => {
  let alert_param = {
                          title: 'System Error',
                          message: error,
                          ok_text: 'OK',
                          confirm: false,
                          alertHandler: this.alertHandler,
                          stack: {id: 0}
                        }
      this.setState({ ready: true, alert_param: alert_param, task_loading: false});
  }
  processIndexData = (result) => {
  // if(result.error_code === 0) {
    console.log("indexresult", result);
    const contacts = JSON.parse(localStorage.getItem("contactlist"));
    console.log("contacts:", contacts);
    let is_admin = Store.getStoreData("is_admin");
    if (is_admin) {
      Store.updateStore("env", "csl");
      Store.updateStore("my_reviews", result.result);
      this.setState({ task_loading: false });
    } else {
      let gc_companies = result.result.gc_companies;
      // gc_companies.forEach((gc) => {
      //   for (let key in gc.users) {
      //     if (key.toString() in contacts.result.contactlist) {
      //       gc.users[key.toString()] = {
      //         ID: contacts.result.contactlist[key.toString()].ID,
      //         ContactName: contacts.result.contactlist[key.toString()].ContactName,
      //         email: contacts.result.contactlist[key.toString()].EmailAddress,
      //         role: contacts.result.user_roles.filechecker[key.toString()],
      //       };
      //     } else {
      //       gc.users[key.toString()] = {
      //         ID: key,
      //         ContactName: "Inactive User",
      //         email: "",
      //         role: "no_access",
      //       };
      //     }
      //   }
      // });
      gc_companies.forEach((gc) => {
        for (let key in gc.users) {
          let user_exist = key.toString() in contacts.result.contactlist;
          if (user_exist === true) {
            if (contacts.result.contactlist[key.toString()].IsActive === true && contacts.result.contactlist[key.toString()].IsDeleted === false) {
              user_exist = true;
            } else {
              user_exist = false;
            }
          }
          if (user_exist === true) {
            gc.users[key.toString()] = {
              ID: contacts.result.contactlist[key.toString()].ID,
              ContactName: contacts.result.contactlist[key.toString()].ContactName,
              email: contacts.result.contactlist[key.toString()].EmailAddress,
              role: contacts.result.user_roles.filechecker[key.toString()],
            };
          } else {
            gc.users[key.toString()] = {
              ID: key,
              ContactName: contacts?.result?.contactlist[key.toString()]?.ContactName + " (Inactive)",
              email: "",
              role: "no_access",
            };
          }
        }
      });
      const parent_company = gc_companies.find((gc) => gc.is_gc === false);
      let parent_has_group_companies = true;
      if (gc_companies.length === 1 && gc_companies[0].is_gc === false) parent_has_group_companies = false;
      const loggedin_user_info = parent_company.users[result.result.loggedin_user_id.toString()];
      let gc_assoc = {};
      gc_companies.forEach((gc) => {
        gc_assoc[gc.id] = gc;
      });
      let admin_managers = {};
      for (let key in parent_company.users) {
        if (parent_company.users[key.toString()].role === "admin_manager") {
          admin_managers[key.toString()] = parent_company.users[key.toString()];
        }
      }
      for (let key in gc_assoc) {
        for (let akey in admin_managers) {
          if (akey.toString() in gc_assoc[key.toString()].users === false) {
            gc_assoc[key.toString()].users[akey.toString()] = admin_managers[akey.toString()];
          }
        }
      }
      Store.updateStore("users", parent_company.users);
      Store.updateStore("loggedin_user_info", loggedin_user_info);
      Store.updateStore("admin_managers", admin_managers);
      Store.updateStore("env", "select");
      Store.updateStore("role", loggedin_user_info.role);
      Store.updateStore("module_config", result.result.module_config);
      Store.updateStore("parent_has_group_companies", parent_has_group_companies);
      Store.updateStore("gc_companies", gc_companies);
      Store.updateStore("my_reviews", result.result.my_reviews);
      Store.updateStore("my_active_reviews", result.result.my_active_reviews);
      Store.updateStore("model_reviews", result.result.model_reviews);
      Store.updateStore("parent_company", parent_company);
      Store.updateStore("gc_assoc", gc_assoc);
      Store.updateStore("groupings", result.result.groupings);
      console.log("loggedin_user_info:", loggedin_user_info);
      console.log("gc_assoc:", gc_assoc);
    }
    this.setState({ ready: true, task_loading: false });
  //}else{
      // let alert_param = {
      //                     title: 'System Error',
      //                     message: result.error_msg,
      //                     ok_text: 'OK',
      //                     confirm: false,
      //                     alertHandler: this.alertHandler,
      //                     stack: {id: 0}
      //                   }
      // this.setState({ ready: true, alert_param: alert_param, task_loading: false});
    //}
  };
  alertHandler = (result, stack) => {
    // this.setState({alert_param: null})
    // window.location = '/select/ServiceBasedLanding';
    Reload.ReloadPage();
  }
  render() {
    if (this.state.task_loading) {
      if (Store.getStoreData("filechecker-index") === null) {
        return (
          <div>
            <CSLPageloader />
          </div>
        );
      }
    }
    if (!this.state.ready) {
      return (
        <div>
          <CSLLoader />
        </div>
      );
    }
    if(this.state.alert_param !== null) {
      return <AlertBox alertParam = {this.state.alert_param} />
    }
    return (
      <Router>
        <Switch>
          <Route path="/config" exact>
            <ConfigIndex />
          </Route>
          <Route path="/moduleconfig" exact>
            <ModuleConfigHOC />
          </Route>
          <Route path="/register" exact>
            <RegisterHome />
          </Route>
          <Route path="/moduleconfig/:requester" exact component={ModuleConfigHOC} />
          <Route path="/reporting" exact>
            <ReportingHOC />
          </Route>
          <Route path="/" exact>
            <Index refreshCallback={this.refreshState} noParam={true} />
          </Route>
          <Route path="/:taskId" exact component={Index} />
        </Switch>
      </Router>
      );
  }
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = async () => {
    console.log('this.props', this.props)
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('is_debug', false)
    Store.updateStore('api_url', api_url)

    await authService.refresh()
    await siteLayout()
    this.setState({ready: true})
  }

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
    if(!this.state.ready) {
        return <div>Loading...</div>
    }
    // let appContent = <AppContent />
    //
    // return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>

    return <AppContent />
  }
}

export default App;
