import React from 'react';
import styled, { keyframes } from 'styled-components';
import Store from '../Common/Store';
import { FaHome, FaCog, FaWrench, FaTasks, FaBook, FaBookOpen } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import * as Style from './Common/StyledComponents';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseBlank, faGear, faSquareList, faChartLine, faFolderOpen, faClipboardList} from "@fortawesome/pro-thin-svg-icons";


class DefaultHeader extends React.Component
{
	render()
	{
		let use_site_layout = false
		if(localStorage.getItem('site_layout')!==null){
			let site_layout = JSON.parse(localStorage.getItem('site_layout'));
			use_site_layout = site_layout.result.use_site_layout;
		}
		if(use_site_layout) return (<div></div>)
		let role = Store.getStoreData('role')
		return (
			<Style.HeadContainer>
				<a href={'/'}>
					<Style.HomeLogo style={{color: "#282828"}}><FontAwesomeIcon icon={faHouseBlank} style={{ color: '#282828' }} /></Style.HomeLogo>
					<Style.HomeText style={{color: "#282828"}}>
							<span style={{fontWeight: "600", cursor: "pointer"}}> File Checker</span>
					</Style.HomeText>
				</a>
				{role === 'admin_manager' &&
					<Style.Categories title='Module Config' >
						<a href={'/moduleconfig'}>
							<FontAwesomeIcon icon={faGear} style={{ color: '#282828' }} />
						</a>
					</Style.Categories>
				}
				{role === 'admin_manager' &&
					<Style.Categories title='Audit Logs' >
						<a href={process.env.REACT_APP_AUDIT_URL}>
							<FontAwesomeIcon icon={faSquareList} style={{ color: '#848484', cursor: 'pointer' }} />
						</a>
					</Style.Categories>
				}
				{role === 'admin_manager' &&
					<Style.Categories title='Filechecker Manager' >
						<a href={'/config'}>
							<FontAwesomeIcon icon={faFolderOpen} style={{ color: '#848484', cursor: 'pointer' }} />
						</a>
					</Style.Categories>
				}
				{role !== 'team' &&
					<Style.Categories title='Filechecker Register' >
						<a href={'/register'}>
							<FontAwesomeIcon icon={faClipboardList} style={{ color: '#282828', cursor: 'pointer' }} />
						</a>
					</Style.Categories>
				}
				<div style={{clear: "both"}}></div>
			</Style.HeadContainer>
		);
	}
}

export default DefaultHeader;