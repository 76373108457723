import React from 'react';
import moment from 'moment';
import CSLTable from './CSLTable';
// import AdvanceFilter from './AdvanceFilter';
// import ReportModal from './ReportModal';
// import PdfGenerator from './PdfGenerator';
import CSLReportModal from './CSLReportModal';
import PdfGenerator from './PdfGenerator';

import Store from '../../../Common/Store';
import APICall from '../../../Common/APICall.js';
import Utils from '../../../Common/Utils.js';
import styled from 'styled-components';



const RButton = styled.button`
	background-color: #1E3E62;
    color: #ffffff;
    padding: 10px 15px;
    border: 1px solid #1E3E62;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
	display: block;
	box-sizing: border-box;
	height: calc(100vh-200px);
	width: 100%;
	background-color: #ffffff;
	position: absolute;
	z-index: 1000;
	opacity: 0.8;
	top: 0px;
`;
const Section88 = styled.div`
	width: calc(100% - 10px);
	box-sizing: border-box;
	float: left;
`;
const Section12 = styled.div`
	width: 12%;
	box-sizing: border-box;
	float: left;
`;
const FilterButton = styled.button`
    box-sizing: border-box;
    background-color:#EEF2F7;
    color: #243E57;
    border: 1px solid #243E57;
    width: 160px;
    font-size: 13px;
    height: 30px;
    border-radius: 100px / 70px;
    margin-right:3px;
    margin-top:7px;
    outline:none;
    cursor: pointer;
`;

const DummyOverlay = styled.div`
    display: table;
    box-sizing: border-box;
    height: calc(100vh - 65px);
    width: calc(100vw - 300px);
    background-color: #ffffff;
    position: fixed;
    left: 300px;
    top: 65px;
    z-index: 1000;
    opacity: 0.6;
`;
class RegisterReporting extends React.Component
{
	state = {
				show_filter: true, 
				filters: null, 
				pill_filters: null, 
				show_report: false, 
				generate: false, 
				groupby_selections: [], 
				sortby_selected: {accessor: 'none', order: 'Descending'},
				tilte_pdf : null,
        		orientation:'portrait',
				pending_headerText:'',
				complete_headerText:""
				//complete_headerText:this.props.compheaderText
				
			}

	data = {};

	constructor(props) {
    	super(props);
    	this.scrollDiv = React.createRef();
  	}

	setCompletedState = () => {
		// if(Store.getStoreData('applied_filters_complete')){
		// 	this.setState({
		// 		complete_headerText:'Completed File Checks: '+Store.getStoreData('applied_filters_complete').review_date.selections.from_date +" - "+Store.getStoreData('applied_filters_complete').review_date.selections.to_date
		// 	})
		// }
		this.setState({
	 		complete_headerText:this.props.compheaderText
		})
				
		// console.log("complete props:", this.props);
		let filters = this.state.filters === null ? this.props.filters : this.state.filters;
  		let pill_filters = this.state.pill_filters === null ? this.props.pillFilters : this.state.pill_filters;
		console.log("setCompletedState")
		let table_data = this.generateTableData();
		for(let pid in pill_filters) {
			pill_filters[pid].count = 0
			for(let rr of table_data.raw_data) {
				pill_filters[pid].count += table_data.row_filters[rr.id][pid]
			}
		}
		let tilte_pdf = this.props.titlePDF === undefined ? '' : this.props.titlePDF;
  		let orientation = this.state.orientation === null ? this.props.orientation : this.state.orientation;		
		this.setState({filters, pill_filters,tilte_pdf, orientation});

		
	}

  	setComponentState = () => {
		// console.log('RegisterReporting props:', this.props);
  		let filters = "";
		let pending_headertxt = "";
		  let applied_filters_pending = Store.getStoreData('applied_filters_pending');
		  //console.log('applied_filters_pending',applied_filters_pending)
		  if(applied_filters_pending){
			filters = applied_filters_pending;
			if(
				Store.getStoreData("loggedin_user_info").role !== "qa_reviewer" && 
				Store.getStoreData("loggedin_user_info").role !== "file_checker"
			){
				pending_headertxt = "Pending File Reviews: "+filters.review_date.selections.from_date+
				" - "+filters.review_date.selections.to_date;
			}
		}else{
			filters = this.state.filters === null ? this.props.filters : this.state.filters;
			if(
				Store.getStoreData("loggedin_user_info").role !== "qa_reviewer" && 
				Store.getStoreData("loggedin_user_info").role !== "file_checker"
			){
				pending_headertxt = this.props.penheaderText;
			}
			
		  }
		  
  		let pill_filters = this.state.pill_filters === null ? this.props.pillFilters : this.state.pill_filters
  		let grouping_config = Store.getStoreData('groupings')
  		let tags_config = grouping_config.tags_config
        //console.log('grouping_config', grouping_config)
  		let groupings = {}
  		let data = this.props.data
        // console.log('this.state.filters', this.state.filters)
		console.log("filtered_pending_tasks ret calling at 144");
      let table_data = this.generateTableData()

  	  // console.log('RegisterReporting pill_filters', pill_filters)

      // console.log('table_data', table_data)
  		

  		for(let pid in pill_filters) {
  			pill_filters[pid].count = 0
  			for(let rr of table_data.raw_data) {
  				pill_filters[pid].count += table_data.row_filters[rr.id][pid]
  			}
  		}

  		for(let r of table_data.raw_data) {
  			for(let id in filters) {
  				if(!(r[id] in filters[id].options)) {
  					filters[id].options[r[id]] = {name: r[id]}
  				}
  			}
  		}
  		let tilte_pdf = this.props.titlePDF === undefined ? '' : this.props.titlePDF;
  		let orientation = this.state.orientation === null ? this.props.orientation : this.state.orientation;
  		this.setState({filters, pill_filters, data, groupings, tilte_pdf, pending_headerText:pending_headertxt,orientation})
		  
		  
  	}



  	componentDidMount() {

		if (this.props.toggle_text === null) {
			this.setComponentState();
		} else {
			this.setCompletedState();
		}
  	}

  	componentDidUpdate(prevprops) {
  		if(prevprops === this.props)return

  		if (this.props.toggle_text === null) {
			this.setComponentState();
		} else {
			this.setCompletedState();
		}
  	}

	// clearAdvancedFilter = () => {
	// 	// window.location.reload()
	// 	this.props.refreshCallback()
	// }
	applyFilter = (filters) => {
		let applied_filters = JSON.parse(JSON.stringify(filters))
        // if ((filters).length === 0) return false;
        console.log('applyAdvancedFilterDate filters', filters)
        // // // console.log('applyAdvancedFilterDate applied_filter', applied_filters)
        this.setState({
			filters: applied_filters
		})
        Store.updateStore('applied_filters', applied_filters)
		Store.updateStore('applied_filters_pending', applied_filters)
        this.props.refreshCallback()
	}

	showOnlyAssign = (flag) =>{
	  this.props.showOnlyAssign(flag);
	}

	setShowTable = (flag, table) => {
	  this.props.setShowTable(flag, table);
	}

	toggleConfigureReport = (event) => {
		let show_configure_report = false;
		show_configure_report = show_configure_report === true ? false : true;
		this.setState({show_configure_report});
	}

	hideReport = () => {
		this.setState({show_report: false});
	}

	toggleGenerate = () => {
		let generate = this.state.generate ? false : true;
		this.setState({generate});
	}

	reportCallback = () => {
		this.setState({show_report: true})
	}

	changeSort = (sortby) => {
        let data = JSON.parse(JSON.stringify(this.state.data));
        this.data.sortby = sortby;
        this.setState({data: this.data, sortby_selected: sortby.selected});
	}

	changeGroup = (groupby) => {
        let data = JSON.parse(JSON.stringify(this.data));
        this.data.groupby = groupby;
        this.setState({data: this.data, groupby_selections: groupby.selections});
	}

    pillClickHandler = (event) => {
        event.preventDefault();
        
        let pill_id = [event.target.name]
        let pill_filters = this.state.pill_filters;
        pill_filters[pill_id].is_active = (pill_filters[pill_id].is_active + 1) % 2
        // this.setState({pill_filters})
        this.props.refreshCallback()
    }
    
    pillClickHandlerCallback = (name) => {
        // event.preventDefault();
        
        let pill_id = name
        let pill_filters = this.state.pill_filters;
        pill_filters[pill_id].is_active = (pill_filters[pill_id].is_active + 1) % 2
        // this.setState({pill_filters})
        this.props.refreshCallback()
    }

    filterDateScore = (date) => {
    	// // // console.log('applyAdvancedFilterDate, date', date)
    	let date_parts = date.split('T')[0].split('-')
    	return parseInt(date_parts[0]) * 10000 + parseInt(date_parts[1]) * 100 + parseInt(date_parts[2])
    }
    tableDateScore = (date) => {
    	let date_parts = date.split('/')
    	return parseInt(date_parts[2]) * 10000 + parseInt(date_parts[1]) * 100 + parseInt(date_parts[0])
    }

    filterRow = (elem) => {
    	let filters = this.state.filters
    	let inclusion = 1
    	console.log('applyAdvancedFilterDate filters, elem', filters, elem)
    	for(let id in filters) {
    		if(Object.keys(filters[id].selections).length === 0) {
    			inclusion *= 1
    		} else {
    			switch(filters[id].type) {
    				case 'text' : inclusion *= elem[id].toLowerCase().includes(filters[id].selections.text.toLowerCase()) ? 1 : 0; break;
    				case 'multi' : 
    				case 'single' : inclusion *= (elem[id] in filters[id].selections ? 1 : 0); break;
    				case 'date_range' : let from_date = this.tableDateScore(filters[id].selections.from_date)
    									let to_date = this.tableDateScore(filters[id].selections.to_date)
    									let table_date = this.tableDateScore(elem[id])
    									// // console.log('applyAdvancedFilterDate, from_date, to_date, table_date', from_date, to_date, table_date)
    									inclusion *= (table_date >= from_date && table_date <= to_date) ? 1 : 0; break;
    			}
    		}
    	}

    	return (inclusion > 0)
    }

    getInclusion = (row_filter) => {
    	let pill_filters = this.state.pill_filters
    	// // console.log('getInclusion pill_filters, row_filter', pill_filters, row_filter)

    	let included_count = 0
    	for(let pid in pill_filters) {
    		included_count += pill_filters[pid].is_active * row_filter[pid]
    	}
		console.log("included_count::",included_count)
    	return (included_count > 0)
    }

    generateTableData = () => {
		//alert(10)
    	let data = this.props.processTableData()
    	//console.log("filtered_pending_tasks ret  2.1===>", data);
    	let groupings = Store.getStoreData('groupings')
    	let gc_assoc = Store.getStoreData('gc_assoc')
    	let grouping_tags = {}
  		for(let t of groupings.tags_config) {
  			let id = Object.keys(t)[0]
  			grouping_tags[id] = t[id]
  		}
    	let tags = {}
    	for(let id in groupings.tags) {
    		tags[id] = {}
    		for(let g of groupings.tags[id]) {
    			tags[id][g.id] = g.name
    		}
    	}
    	// // // console.log('generateTableData gc_assoc', gc_assoc)
    	for(let r of data.raw_data) {
    		let company_id = (parseInt(r.company_id) % 100000).toString()
    		let gc_grouping = (company_id in gc_assoc && 'tags_config' in gc_assoc[company_id]) ? gc_assoc[company_id].tags_config : {}
    		// // // console.log('generateTableData gc_grouping', gc_grouping)
    		for(let tid in grouping_tags) {
    			if(tid in gc_grouping && tid in tags) {
    				if(gc_grouping[tid] in tags[tid]) {
    					r[tid] = tags[tid][gc_grouping[tid]]
    				} else {
    					r[tid] = 'None'
    				}
    			} else {
    				r[tid] = 'None'
    			}
    		}
    	}
    	// // // console.log('generateTableData data.raw_data', data.raw_data)
    	let ret = {columns: data.columns, data: [], raw_data: [], row_filters: data.row_filters} 
		console.log("raw_data 2.2::",data.raw_data)
    	for(let elem of data.raw_data) {
			if(this.filterRow(elem)){
				if(this.getInclusion(data.row_filters[elem.id])) {
					// if(elem.cur_lane === 'COMP_FCK_COMPLETE' || elem.cur_lane === 'COMP_FCK_QC_COMPLETE' ){
					// 	console.log("raw_data5::",elem)
					// }
					
				  	ret.data.push(elem);
					  // if(elem.cur_lane === 'COMP_FCK_COMPLETE' || elem.cur_lane === 'COMP_FCK_QC_COMPLETE' ){
					  // console.log("raw_data5 thisret=>",ret.data)
					  // }
				}
				ret.raw_data.push(elem);
			}    		
    	}
		//ret.raw_data = ret.data = data.raw_data
		console.log("final thisret=>",ret)
    	return ret
    }

    goToReport = () => {
    	this.setState({show_report: true})
    }

	applyCompletedFilter = (filters) => {
		
		this.props.showLoader(1)
		console.log('applyCompletedFilter', filters);
		Store.updateStore('applied_filters_complete', filters)
		localStorage.removeItem("applied_reg_data_complete")
		
		console.log('applyCompletedFilter apply', filters);
		const postData = {command: "get_filtered_register_completed_tasks", filters};
        let api = new APICall();
        api.command(postData, this.processFilteredRegisterCompletedTasks);
	}

	processFilteredRegisterCompletedTasks = (result) => {
		//alert(1)
		// let filters = Store.getStoreData('applied_filters_complete');
		// this.setState({
		// 	complete_headerText:"Completed File Checks: "+filters.review_date.selections.from_date+" - "+filters.review_date.selections.to_date
		// })
		// console.log('applyCompletedFilter result', result);
		Store.updateStore("completed_fcs", result);
		Store.updateStore("applied_reg_data_complete", result);
		this.props.showLoader(0)
		if (this.props.headerText === "Completed File Checks") {
			//alert(1.1)
			this.props.refreshCompCallback();
		} else {
			this.props.refreshCallback();
		}
	}
	
	clearAdvancedFilter = () => {
     	this.setState({complete_headerText:'Completed File Checks: --/--/---- - --/--/---'});
		this.props.clearAdvancedFilter();
  	}
	toggleOrientation = (mode) => {
    	this.setState({orientation: mode});
  	}
	render()
	{
		console.log("Render state=>",this.state)
		console.log("Render props=>",this.props)

		return (
			<div style={{position: "relative", backgroundColor: "#F2F2F2", padding: "10px 0px"}} ref={this.scrollDiv}>
				{/* <Section88>
					{
						Object.keys(this.state.pill_filters).map((pid, i) => {
							//// // console.log('pills p', p)
							if (this.state.pill_filters[pid].is_visible === true) {
								return(<FilterButton key={i} style={{backgroundColor: this.state.pill_filters[pid].is_active ? '#243E57' : '#EEF2F7', 
									color:  this.state.pill_filters[pid].is_active ? "#EEF2F7" : '#243E57' , marginRight:"15px"}} name={this.state.pill_filters[pid].name} onClick={this.pillClickHandler}>
									<span style={{float: 'left'}}>&#10003;    </span>{this.state.pill_filters[pid].display}<span style={{float: 'right'}}> 
									{this.state.pill_filters[pid].count}</span>
								</FilterButton>);
							}
						})
					}
				</Section88> */}
				<div style={{clear: "both", height: '3px'}}></div>
				<div style={{clear: "both"}}></div>

				{/* <AdvanceFilter 
					filters={this.state.filters} 
					applyFilter={this.applyFilter} 
					clearAdvancedFilter={this.clearAdvancedFilter}
				/> */}
				<CSLTable export={true}  goToReport={this.goToReport} export_file_name={this.props.export_file_name}  
					add={false}
					custom_fields={true}
					processData={this.generateTableData} 
					prefered_columns = {this.props.preferedColumns} 
					export_columns = {this.props.preferedColumns} 
					headerText={this.props.toggle_text === null ? this.state.pending_headerText : this.state.complete_headerText} 
					tableRows={10} 
					refreshCallback={this.refreshCallback} 
					// qcbutton={this.props.qcbutton} 
					// toggleAssignQcModal={this.props.toggleAssignQcModal} 
					// qa_enabled={this.props.quality_assurance_enabled} 
					// filters={this.state.filters} 
					filters={this.props.filters}
					applyFilter={this.props.toggle_text === null ? this.applyFilter : this.applyCompletedFilter} 
					clearAdvancedFilter={this.props.clearAdvancedFilter}
					report={this.props.report}
					pill_filters={this.state.pill_filters}
					pillClickHandler={this.pillClickHandlerCallback}
					buttonSet={this.props.buttonSet}
					toggle_text={this.props.toggle_text}
					toggle_state={this.props.toggle_state}
					toggleCallback={"toggleCallback" in this.props === true ? this.props.toggleCallback : null}
				/>           

				{
					(() => {
						if(this.state.show_report) {
							let fields = this.props.fields
							let data = this.props.data
							data.data = this.generateTableData().data
							// this.data = {
							// 					data: this.generateTableData().data, 
							// 					groupby: {fields: fields, selections: this.state.groupby_selections}, 
							// 					sortby: {fields: fields, selected: this.state.sortby_selected},
							// 					table_headers: fields,
							// 					module: {display: this.props.headerText, id: this.props.moduleId}
							// 				}
							Utils.log('widths new_data', this.data)
							return (
									<div style={{position:'absolute', top:'0px', width:'100%'}}>
										<InactiveOverlay />
										<CSLReportModal
											data={data}
											changeSort={this.changeSort}
											changeGroup={this.changeGroup}
											hideReport={this.hideReport}
											toggleGenerate={this.toggleGenerate}
											filters={this.props.filters}
											toggleOrientation={this.toggleOrientation}
										/>
									</div>
								);
						}
					})()
				}
				{
					(() => {
						if (this.state.generate) {
							return (
								<div style={{position:'absolute', top:'10px', width:'calc(100%-50px)', height: 'calc(100vh-90px)'}}>
									<InactiveOverlay style={{zIndex: "1010"}} />
									<PdfGenerator 
									filters={this.state.filters} 
									pills={this.state.pill_filters} 
									toggleGenerate={this.toggleGenerate} 
									contactName={Store.getStoreData('contact')} 
									tiltePDF={this.state.tilte_pdf}
                    				orientation={this.state.orientation}
                    				/>
								</div>
							);
						}
					})()
				}
			</div>
		);
	}

	scrollToMyRef = () => this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
}

export default RegisterReporting;



				
				// <CSLTable add={false} showHF={true} export={true} processData={this.generateTableData} 
				// 	headerText={this.props.headerText} HeaderFilter={'Only show Advice Requests assigned to me'} 
				// 	tableRows="10" refreshCallback={this.refreshCallback} showOnlyAssign = {this.props.only_assign_me} 
				// 	only_assign_me={this.props.only_assign_me} show_filter={this.props.show_filter} table="car_register" 
				// 	prefered_columns={this.props.preferedColumns} showOnlyAssign={this.showOnlyAssign} setShowTable={this.setShowTable}
				// 	report={true} goToReport={this.reportCallback} />   
